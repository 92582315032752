<script>
export let constrain = false;
export let nopadding = false;
export let padTop    = false;
export let featured  = false;
</script>


<section class="Section {$$props.class || ''}"
         class:constrain
         class:nopadding
         class:featured
         class:padTop>
  <slot />
</section>


<style lang="scss">
  .Section {
    height: 100%;
    margin: 0 auto;
    color: white;
    font-family: $font_reg;

    &:last-child {
      padding-bottom: 0;
    }

    &.nopadding {
      padding:    0 !important;
      max-width: 100% !important;
    }

    &.padTop {
      @include mobile  { padding-top: 13.125rem; }
      @include tablet  { padding-top: 9.06rem;   }
      @include desktop { padding-top: 12.18rem;  }
    }

    &.featured {
      margin: 0 auto;

      @include tablet {
        padding-top: $spacing_featured_tablet;
      }

      @include desktop {
        padding-top: $spacing_featured_desktop;
      }
    }

    @include mobile {
      max-width: $section_main_mobile;
      padding: calc($gutter_sm / 2) 0;
    }

    @include tablet {
      max-width: $section_main_tablet;
      padding: calc($gutter_md / 2) 0;
    }

    @include desktop {
      max-width: $section_main_desktop;
      padding: calc($gutter / 2) 0;
    }

    @media (min-width: 1600px) {
      &.constrain {
        max-width: 60vw;
      }
    }
  }
</style>
